import React, { Component } from 'react';
import * as UI from '@vkontakte/vkui';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';

class CreditsPanel extends Component {
  render() {
    const osname = UI.platform();
    return (
      <UI.Panel id={this.props.id}>
        <UI.PanelHeader
          left={<UI.HeaderButton onClick={this.props.back}>{osname === UI.IOS ? <Icon28ChevronBack/> : <Icon24Back/>}</UI.HeaderButton>}
        >
          Об игре
        </UI.PanelHeader>
        <UI.Group title="Дом кино">
          <UI.List>
            <UI.ListItem multiline>
              <UI.Link href="https://www.domkino.tv/">Официальный сайт канала Дом кино</UI.Link>
            </UI.ListItem>
            <UI.ListItem multiline>
              <UI.Link href="https://www.domkino.tv/schedule">Программа передач</UI.Link>
            </UI.ListItem>
            <UI.ListItem multiline>
              <UI.Link href="https://www.domkino.tv/news">Журнал</UI.Link>
            </UI.ListItem>
            <UI.ListItem multiline>
              <UI.Link href="https://www.domkino.tv/news/tests">Кинотесты</UI.Link>
            </UI.ListItem>
          </UI.List>
        </UI.Group>
      </UI.Panel>
    );
  }
}

export default CreditsPanel;
