import React, { Component } from 'react';
import * as UI from '@vkontakte/vkui';

class Footer extends Component {
  render() {
    return (
      <UI.Div className="footer">
        <UI.Button type="cell" align="center" onClick={this.props.onClick}>
          Об игре
        </UI.Button>
      </UI.Div>
    );
  }
}

export default Footer;
