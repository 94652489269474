import React, {Component} from 'react';
import * as connect from '@vkontakte/vkui-connect';
// import connect from '@vkontakte/vkui-connect-mock';
import * as UI from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import Icon24Play from '@vkontakte/icons/dist/24/play';
import Icon24Restart from '@vkontakte/icons/dist/24/replay';
import Icon24Share from '@vkontakte/icons/dist/24/share';
import Footer from './components/Footer/Footer';
import CreditsPanel from "./components/CreditsPanel/CreditsPanel";
import './App.scss';

class App extends Component {

  constructor(props) {
    super(props);

    this.imagesArr = [
      {src: '/red/1.png',    correct: 'red',   name: 'Михаил Васильевич Фрунзе',        movie: 'Бег'},
      {src: '/red/2.png',    correct: 'red',   name: 'Баев',                            movie: 'Бег'},
      {src: '/red/3.png',    correct: 'red',   name: 'Начальник штаба Конармии',        movie: 'Новые приключения неуловимых'},
      {src: '/red/4.png',    correct: 'red',   name: 'Валерка',                         movie: 'Новые приключения неуловимых'},
      {src: '/red/5.png',    correct: 'red',   name: 'Данька Щусь',                     movie: 'Новые приключения неуловимых'},
      {src: '/red/6.png',    correct: 'red',   name: 'Егор Петрович Шилов',             movie: 'Свой среди чужих, чужой среди своих'},
      {src: '/red/7.png',    correct: 'red',   name: 'Василий Антонович Сарычев',       movie: 'Свой среди чужих, чужой среди своих'},
      {src: '/red/8.png',    correct: 'red',   name: 'Арсентий Евсюков',                movie: 'Сорок первый'},
      {src: '/red/9.png',    correct: 'red',   name: 'Марютка Басова',                  movie: 'Сорок первый'},
      {src: '/red/10.png',   correct: 'red',   name: 'Андрей Некрасов',                 movie: 'Служили два товарища'},
      {src: '/red/11.png',   correct: 'red',   name: 'Иван Трофимович Карякин',         movie: 'Служили два товарища'},
      {src: '/red/12.png',   correct: 'red',   name: 'Петька',                          movie: 'Чапаев'},
      {src: '/red/13.png',   correct: 'red',   name: 'Василий Иванович Чапаев',         movie: 'Чапаев'},
      {src: '/red/14.png',   correct: 'red',   name: 'Бумбараш',                        movie: 'Бумбараш'},
      {src: '/red/15.png',   correct: 'red',   name: 'Лёвка Демченко',                  movie: 'Бумбараш'},
      {src: '/red/16.png',   correct: 'red',   name: 'Назар Васильевич Дума',           movie: 'Свадьба в Малиновке'},
      {src: '/red/17.png',   correct: 'red',   name: 'Комиссар Сердюк',                 movie: 'Гори, гори, моя звезда'},
      {src: '/red/18.png',   correct: 'red',   name: 'Сергей Кусков',                   movie: 'Кто заплатит за удачу'},
      {src: '/red/19.png',   correct: 'red',   name: 'Артузов',                         movie: 'Операция "Трест"'},
      {src: '/red/20.png',   correct: 'red',   name: 'Сиротин',                         movie: 'Адъютант его превосходительства'},
      {src: '/white/1.png',  correct: 'white', name: 'Пётр Сергеевич Овечкин',          movie: 'Новые приключения неуловимых'},
      {src: '/white/2.png',  correct: 'white', name: 'Леопольд Сергеевич Кудасов',      movie: 'Новые приключения неуловимых'},
      {src: '/white/3.png',  correct: 'white', name: 'Ротмистр Лемке',                  movie: 'Свой среди чужих, чужой среди своих'},
      {src: '/white/4.png',  correct: 'white', name: 'Ванюкин',                         movie: 'Свой среди чужих, чужой среди своих'},
      {src: '/white/5.png',  correct: 'white', name: 'Керенский Александр Фёдорович',   movie: 'Адмирал'},
      {src: '/white/6.png',  correct: 'white', name: 'Владимир Оскарович Каппель',      movie: 'Адмирал'},
      {src: '/white/7.png',  correct: 'white', name: 'Александр Васильевич Колчак',     movie: 'Адмирал'},
      {src: '/white/8.png',  correct: 'white', name: 'Вадим Николаевич Говоруха-Отрок', movie: 'Сорок первый'},
      {src: '/white/9.png',  correct: 'white', name: 'Александр Брусенцов',             movie: 'Служили два товарища'},
      {src: '/white/10.png', correct: 'white', name: 'Барон Краузе',                    movie: 'Служили два товарища'},
      {src: '/white/11.png', correct: 'white', name: 'Сергей Николаевич Бороздин',      movie: 'Чапаев'},
      {src: '/white/12.png', correct: 'white', name: 'Стригунов',                       movie: 'Бумбараш'},
      {src: '/white/13.png', correct: 'white', name: 'Штабс-капитан Чечель',            movie: 'Свадьба в Малиновке'},
      {src: '/white/14.png', correct: 'red',   name: 'Павел Андреевич Кольцов',         movie: 'Адъютант его превосходительства'},
      {src: '/white/15.png', correct: 'white', name: 'Александр Якушев ',               movie: 'Операция "Трест"'},
      {src: '/white/16.png', correct: 'white', name: 'Штабс-капитан',                   movie: 'Гори, гори, моя звезда'},
      {src: '/white/17.png', correct: 'white', name: 'Офицер',                          movie: 'Гори, гори, моя звезда'},
      {src: '/white/18.png', correct: 'white', name: 'Дмитрий Чумак',                   movie: 'Кто заплатит за удачу'},
      {src: '/white/19.png', correct: 'white', name: 'Николай Григорьевич Щукин',       movie: 'Адъютант его превосходительства'},
      {src: '/white/20.png', correct: 'white', name: 'Григорий Лукьянович Чарнота',     movie: 'Бег'},
    ];

    this.shareTextArr = [
      'Не самый лучший результат...',
      'Неплохой результат!',
      'Отличный результат!',
    ];

    this.images = this.getRandom(this.imagesArr, 20);

    const activePanel = 'splashPanel';
    this.prevPanel = activePanel;
    this.timer = 0;
    this.defaultSeconds = 60;
    this.defaultImage = {name: '', src: '/images/cover.jpg'};
    this.answers = {};
    this.correctAnswers = 0;

    this.state = {
      time: {},
      user: {},
      token: '',
      correctAnswer: '',
      seconds: 0,
      percents: 100,
      image: this.defaultImage,
      imageNum: -1,
      activePanel: activePanel,
      results: { total: this.images.length, correct: 0, shareText: '' },
    };

    this.startQuiz = this.startQuiz.bind(this);
    this.countDown = this.countDown.bind(this);
    this.VKWallPost = this.VKWallPost.bind(this);
    this.initVK();
  }

  getRandom(arr, n) {
    let result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      let x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  initVK() {
    connect.subscribe((e) => {
      switch (e.detail.type) {
        case 'VKWebAppGetUserInfoResult':
          this.setState({ user: e.detail.data });
          console.log('getUser');
          break;
        case 'VKWebAppAccessTokenReceived':
          this.setState({
            token: e.detail.data.access_token
          });
          break;
        case 'VKWebAppShowWallPostBoxResult':
          console.log('wall post');
          console.log(e.data);
          break;
        case 'VKWebAppShowWallPostBoxFailed':
          console.log('wall post filed');
          console.log(e.data);
          break;
        case 'VKWebAppAccessTokenFailed':
          console.log('token filed');
          break;
        case 'VKWebAppCallAPIMethodResult':
          console.log(e.detail);
          break;
        case 'VKWebAppCallAPIMethodFailed':
          console.log(e.detail.data);
          break;
        default:
          console.log(e.detail.type);
      }
    });
    setTimeout(
      () => {
        connect.send("VKWebAppGetAuthToken", {"app_id": 6329743, "scope": "wall"})
      },
      1000
    );
  }


  VKWallPost() {
    const correct = this.state.results.correct;
    const total = this.state.results.total;
    connect.send(
      'VKWebAppShowWallPostBox',
      {
        'message': `Я отличаю красных от белых! Мой результат: ${correct} из ${total}.`,
        'attachments': 'https://www.domkino.tv/'
      }
    );
  }

  startTimer() {
    if (this.timer === 0) {
      this.setState({
        seconds: this.defaultSeconds,
      });
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  results() {
    let correctTextN = Math.round((this.correctAnswers / this.images.length) / (1 / this.shareTextArr.length));
    if (correctTextN >= this.shareTextArr.length) {
      correctTextN = this.shareTextArr.length - 1;
    }
    return {
      shareText: this.shareTextArr[correctTextN],
      total: this.images.length,
      correct: this.correctAnswers,
    };
  }

  stopQuiz() {
    clearInterval(this.timer);
    this.timer = 0;
    this.setState({
      activePanel: 'resultsPanel',
      imageNum: -1,
      image: this.defaultImage,
      percents: 100,
      results: this.results(),
    });
    this.correctAnswers = 0;
    this.images = this.getRandom(this.imagesArr, 20);
  }

  nextImageSwitch() {
    const imageNum = this.state.imageNum + 1;
    if (imageNum >= this.images.length || typeof this.images[imageNum] === 'undefined') {
      this.stopQuiz();
    } else {
      this.setState({
        imageNum,
        image: this.images[imageNum]
      });
    }
  }

  nextImage() {

    if (typeof this.images[this.state.imageNum] !== 'undefined' && this.images[this.state.imageNum].correct) {
      this.setState({correctAnswer: this.images[this.state.imageNum].correct});
      setTimeout(() => {
        this.setState({correctAnswer: ''});
        this.nextImageSwitch();
      }, 500);
    } else {
      this.nextImageSwitch();
    }

  }

  countDown() {
    const seconds = this.state.seconds - 1;
    const percents = Math.round(100 * seconds / this.defaultSeconds);
    this.setState({
      seconds,
      percents,
    });
    if (seconds <= 0) {
      this.stopQuiz();
    }
  }

  startQuiz() {
    this.setState({activePanel: 'quizPanel'});
    this.startTimer();
    this.nextImage();
  }

  setAnswer(value) {
    if (this.images[this.state.imageNum].correct === value) {
      this.correctAnswers++;
    }
    if (this.timer > 0) {
      this.nextImage();
    }
  }

  openCredits() {
    this.prevPanel = this.state.activePanel;
    this.setState({activePanel: 'creditsPanel'});
  }

  closeCredits () {
    this.setState({activePanel: this.prevPanel});
  }

  render() {
    return (
      <UI.Root activeView="mainView">
        <UI.View id="mainView" activePanel={this.state.activePanel}>

          <UI.Panel id="splashPanel">
            <UI.PanelHeader>
              Красные или белые
            </UI.PanelHeader>
            <UI.Group className="header">
              <UI.Div className="header__image">
                <img src={process.env.PUBLIC_URL + '/images/cover.jpg'} alt="Дом кино" />
              </UI.Div>
              <UI.Div className="header__title">
                <h1>К юбилею истерна «Свой среди чужих, чужой среди своих»!</h1>
              </UI.Div>
              <UI.Div className="header__title">
                Ротмистр Лемке, чекист Шилов, железнодорожник Ванюкин… А вы помните, кто был белым, а кто красным? А в других фильмах?
                <br/>
                У вас есть 20 киногероев и 1 минута, чтобы определить: кто красный, а кто белый?
              </UI.Div>
              <UI.Div>
                <UI.Button className="header__start"
                           onClick={this.startQuiz}
                           level="1"
                           before={<Icon24Play/>}
                           size="xl"
                >Начать</UI.Button>
              </UI.Div>
            </UI.Group>
            <Footer onClick={this.openCredits.bind(this)} />
          </UI.Panel>

          <UI.Panel id="quizPanel">
            <UI.PanelHeader>
              Красные или белые
            </UI.PanelHeader>

            <UI.Group className="quiz">
              <UI.Div className="timer">
                <UI.Progress style={{width: '100%'}} value={this.state.percents} />
              </UI.Div>
              <UI.Div style={{display: 'flex', justifyContent: 'center'}} className="quiz__image">
                <img src={process.env.PUBLIC_URL + '/images/' + this.state.image.src} alt={this.state.image.name} />
              </UI.Div>
              <UI.Div style={{textAlign: 'center', flexWrap: 'wrap'}}>
                  <h1 className="quiz__title">{this.state.image.name}</h1>
                  <h2 className="quiz__title">&laquo;{this.state.image.movie}&raquo;</h2>
              </UI.Div>
              <UI.Div style={{textAlign: 'center'}}>
              </UI.Div>
              <UI.Div className={`quiz__answers quiz__answers_correct_${this.state.correctAnswer}`}>
                  <UI.Button className="quiz__answer quiz__answer_red" onClick={()=>this.setAnswer('red')} size="xl" stretched>Красный</UI.Button>
                  <UI.Button className="quiz__answer quiz__answer_white" onClick={()=>this.setAnswer('white')} size="xl" stretched>Белый</UI.Button>
              </UI.Div>
            </UI.Group>
            <Footer onClick={this.openCredits.bind(this)} />
          </UI.Panel>

          <UI.Panel id="resultsPanel">
            <UI.PanelHeader>
              Красные или белые
            </UI.PanelHeader>
            <UI.Group className="results">
              <UI.Div>
                <div className="results__numbers">
                {this.state.results.correct} / {this.state.results.total}
                </div>
              </UI.Div>
              {/*<UI.Div className="results__text">
                {this.state.results.shareText}
              </UI.Div>*/}
              <UI.Div className="results__image">
                <img src={process.env.PUBLIC_URL + '/images/results2.jpg'} alt="Смотрите любимые фильмы на телеканале Дом кино" />
              </UI.Div>
              <UI.Div className="results__buttons">
                <UI.Button className="header__start"
                           onClick={this.VKWallPost}
                           level="primary"
                           before={<Icon24Share/>}
                           size="l"
                           style={{ marginRight: 8 }}
                >Поделиться</UI.Button>
                <UI.Button className="header__start"
                           onClick={this.startQuiz}
                           level="secondary"
                           before={<Icon24Restart/>}
                           size="l"
                >Пройти тест еще раз</UI.Button>
              </UI.Div>
            </UI.Group>
            <Footer onClick={this.openCredits.bind(this)} />
          </UI.Panel>

          <CreditsPanel id="creditsPanel" back={this.closeCredits.bind(this)}/>
        </UI.View>
      </UI.Root>
    );
  }
}

export default App;
